import Axios from 'axios';
import i18n from '../../i18n';

export default {
    state: {
        token: null,
        userData: null,
        locale: 'ua',
        socialLoginResponse: null
    },
    actions: {
        login(ctx, data) {
            return Axios.post('/v1/login', data).then((response) => {
                ctx.commit('setToken', response.data.data.token);
            });
        },
        socialLogin(ctx, data) {
            return Axios.post(
                `/v1/social_auth/${data.provider}`,
                data.response
            );
        },
        socialRegister(ctx, data) {
            const params = Object.assign(data.response, {
                country_uuid: data.country,
                company_name: data.company
            });
            return Axios.post(
                `/v1/social_register/${data.provider}`,
                params
            ).then((response) => {
                ctx.commit('setToken', response.data.data.token);
            });
        },
        posterLogin(ctx, data) {
            return Axios.post('/v1/poster_auth', {
                account: data.account,
                code: data.code
            });
        },
        posterRegister(ctx, data) {
            return Axios.post('/v1/poster_register', {
                account: data.account,
                code: data.code,
                country_uuid: data.countryUuid,
                company_name: data.storeName
            }).then((response) => {
                ctx.commit('setToken', response.data.data.token);
            });
        },
        completeRegistration(ctx, data) {
            return Axios.post('/v1/complete_register', {
                company_name: data.storeName,
                country_uuid: data.country
            });
        },
        logout(ctx) {
            return Axios.post('/v1/logout').then(() => {
                ctx.commit('clearToken');
            });
        },
        getUserData(ctx) {
            return Axios.get('/api/self').then((response) => {
                const data = response.data.data;
                ctx.commit('setUserData', data);
                ctx.commit('setLocale', data.locale);
            });
        },
        putUserData(ctx, data) {
            let result = {};
            for (let key in data) {
                result[key] = data[key];
            }
            return Axios.put('/api/self', result);
        }
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload;
            Axios.defaults.headers.common.Authorization = 'Bearer ' + payload;
            localStorage.setItem('token', JSON.stringify(payload));
        },
        clearToken(state, payload) {
            state.token = payload;
            Axios.defaults.headers.common.Authorization = '';
            localStorage.removeItem('token');
        },
        setLocale(state, payload) {
            state.locale = payload;
            i18n.locale = payload;
            localStorage.setItem('locale', JSON.stringify(payload));
        },
        setUserData(state, payload) {
            state.userData = payload;
        },
        setSocialLoginResponse(state, payload) {
            state.socialLoginResponse = payload;
        }
    },
    getters: {
        userData: (state) => state.userData,
        token: (state) => state.token,
        locale: (state) => state.locale,
        socialLoginResponse: (state) => state.socialLoginResponse
    }
};
