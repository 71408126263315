import Axios from 'axios';

export default {
    state: {
        posterAccounts: [],
        currentPosterAccount: null,
        currentPosterAccountUuid: '',
        posterChannels: [],
        selectedPosterChannel: {
            uuid: null,
            name: null
        },
        loadingPosterChannelsTable: false,
        createPosterChannelPopupActive: false,
        editPosterChannelPopupActive: false,
        subscribePosterPopupActive: false,
        posterChannelSubscribers: [],
        posterChannelSubscribersPopupActive: false
    },
    actions: {
        getPosterAccounts(ctx) {
            return Axios.get('/api/poster_apps').then((response) => {
                ctx.commit('setPosterAccounts', response.data.data);
            });
        },
        getPosterAccount(ctx, data) {
            return Axios.get('/api/poster_apps/' + data.uuid).then(
                (response) => {
                    ctx.commit('setCurrentPosterAccount', response.data.data);
                }
            );
        },
        postPosterAccount(ctx, data) {
            return Axios.post('/api/poster_apps', {
                account: data.account,
                code: data.code
            });
        },
        deletePosterAccount(ctx, data) {
            return Axios.delete('/api/poster_apps/' + data.uuid);
        },
        getPosterChannels(ctx, data) {
            let url = '/api/poster_channels';
            if (data && Object.keys(data).length) {
                url += '?';
                for (let key in data) {
                    url += key + '=' + data[key] + '&';
                }
                if (url.endsWith('&')) {
                    url = url.slice(0, -1);
                }
            }
            return Axios.get(url).then((response) => {
                ctx.commit('setPosterChannels', response.data.data);
            });
        },
        postPosterChannel(ctx, data) {
            return Axios.post('/api/poster_channels', {
                name: data.name,
                poster_app_uuid: data.uuid
            });
        },
        putPosterChannel(ctx, data) {
            return Axios.put('/api/poster_channels/' + data.uuid, data);
        },
        deletePosterChannel(ctx, data) {
            return Axios.delete('/api/poster_channels/' + data.uuid);
        },
        getPosterSubscribers(ctx, data) {
            let url = '/api/poster_subscribers';
            if (data && Object.keys(data).length) {
                url += '?';
                for (let key in data) {
                    url += key + '=' + data[key] + '&';
                }
                if (url.endsWith('&')) {
                    url = url.slice(0, -1);
                }
            }
            return Axios.get(url).then((response) => {
                ctx.commit('setPosterChannelSubscribers', response.data.data);
            });
        },
        deletePosterSubscriber(ctx, data) {
            return Axios.delete('/api/poster_subscribers/' + data.uuid);
        },
        syncPosterMenu(ctx, data) {
            return Axios.post(`/v1/poster_apps/${data.uuid}/sync_menu`, {
                location_id: data.id,
                location_name: data.name,
                category_filters: data.category_filters,
                item_filters: data.item_filters
            });
        }
    },
    mutations: {
        setPosterAccounts(state, payload) {
            state.posterAccounts = payload;
        },
        setCurrentPosterAccount(state, payload) {
            state.currentPosterAccount = payload;
        },
        setCurrentPosterAccountUuid(state, payload) {
            state.currentPosterAccountUuid = payload;
        },
        setPosterChannels(state, payload) {
            state.posterChannels = payload;
        },
        setLoadingPosterChannelsTable(state, payload) {
            state.loadingPosterChannelsTable = payload;
        },
        setSelectedPosterChannel(state, payload) {
            state.selectedPosterChannel = payload;
        },
        setSelectedChannelName(state, payload) {
            state.selectedPosterChannel.name = payload;
        },
        setCreatePosterChannelPopupActive(state, payload) {
            state.createPosterChannelPopupActive = payload;
        },
        setEditPosterChannelPopupActive(state, payload) {
            state.editPosterChannelPopupActive = payload;
        },
        clearPosterChannels(state) {
            state.posterChannels = [];
        },
        setSubscribePosterPopupActive(state, payload) {
            state.subscribePosterPopupActive = payload;
        },
        setPosterChannelSubscribers(state, payload) {
            state.posterChannelSubscribers = payload;
        },
        setPosterChannelSubscribersPopupActive(state, payload) {
            state.posterChannelSubscribersPopupActive = payload;
        }
    },
    getters: {
        posterAccounts: (state) => state.posterAccounts,
        currentPosterAccount: (state) => state.currentPosterAccount,
        terminals: (state) => {
            let result = [];
            //обрезаем слишком длинные названия терминала и локации, от постера, чтобы пройти валидацию по длине строки
            let cutString = (string, replaceString, maxLength) => {
                let length = string.length;
                if (length > maxLength) {
                    let difference = length + replaceString.length - maxLength;
                    let startPosition = length / 2 - difference / 2;
                    let endPosition = length / 2 + difference / 2;
                    return (
                        string.slice(0, startPosition) +
                        '...' +
                        string.slice(endPosition)
                    );
                } else {
                    return string;
                }
            };
            if (state.currentPosterAccount?.terminals) {
                for (
                    let i = 0;
                    i < state.currentPosterAccount.terminals.length;
                    i += 1
                ) {
                    result[i] = _.cloneDeep(
                        state.currentPosterAccount.terminals[i]
                    );
                    if (!result[i].name) {
                        result[i].name = 'Термінал без назви';
                    } else {
                        result[i].name = cutString(result[i].name, '...', 25);
                    }
                    let location = _.find(
                        state.currentPosterAccount.locations,
                        (location) => {
                            return location.id === result[i].location_id;
                        }
                    );
                    if (location.name) {
                        result[i].name +=
                            ' (' + cutString(location.name, '...', 25) + ')';
                    }
                }
            }
            return result;
        },
        currentPosterAccountUuid: (state) => state.currentPosterAccountUuid,
        posterChannels: (state) => state.posterChannels,
        selectedPosterChannel: (state) => state.selectedPosterChannel,
        loadingPosterChannelsTable: (state) => state.loadingPosterChannelsTable,
        createPosterChannelPopupActive: (state) =>
            state.createPosterChannelPopupActive,
        editPosterChannelPopupActive: (state) =>
            state.editPosterChannelPopupActive,
        subscribePosterPopupActive: (state) => state.subscribePosterPopupActive,
        posterChannelSubscribers: (state) => state.posterChannelSubscribers,
        posterChannelSubscribersPopupActive: (state) =>
            state.posterChannelSubscribersPopupActive
    }
};
