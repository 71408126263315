import Axios from 'axios';
import { generateGetUrl } from '../../base';

export default {
    state: {
        dashboardStaticData: {
            SCAN: {
                value: 0,
                title: 'dashboard_scan_qr_text'
            },
            OPEN_MENU: {
                value: 0,
                title: 'dashboard_open_menu_text'
            },
            CALL_BUTTON: {
                value: 0,
                title: 'dashboard_call_button_text'
            },
            REVIEW: {
                value: 0,
                title: 'dashboard_review_text'
            }
        }
    },
    actions: {
        getDashboardStatic(ctx, data) {
            let url = generateGetUrl('/api/dashboards/base', data);
            return Axios.get(url).then((response) => {
                let data = response.data.data;
                ctx.commit('setDashboardStaticData', data);
            });
        }
    },
    mutations: {
        setDashboardStaticData(state, payload) {
            for (let key in payload) {
                if (Object.hasOwn(state.dashboardStaticData, key)) {
                    state.dashboardStaticData[key].value = payload[key];
                }
            }
        }
    },
    getters: {
        dashboardStaticData: (state) => state.dashboardStaticData
    }
};
