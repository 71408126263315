import Axios from 'axios';

export default {
    state: {
        reviews: [],
        loadingReviewsTable: false,
        reviewToShow: null,
        showReviewPopupActive: false,
        reviewsCount: null
    },
    actions: {
        getReviews(ctx, data) {
            let url = '/api/reviews';
            if (data && Object.keys(data).length) {
                url += '?';
                for (let key in data) {
                    url += key + '=' + data[key] + '&';
                }
                if (url.endsWith('&')) {
                    url = url.slice(0, -1);
                }
            }
            return Axios.get(url).then((response) => {
                ctx.commit('setReviews', response.data.data);
            });
        },
        getReview(ctx, data) {
            return Axios.get('/api/reviews/' + data.uuid + '/edit').then(
                (response) => {
                    ctx.commit('setReviewToShow', response.data.data.review);
                }
            );
        },
        deleteReview(ctx, data) {
            return Axios.delete('/api/reviews/' + data.uuid);
        }
    },
    mutations: {
        setReviews(state, payload) {
            state.reviews = payload;
        },
        setLoadingReviewsTable(state, payload) {
            state.loadingReviewsTable = payload;
        },
        setReviewToShow(state, payload) {
            state.reviewToShow = payload;
        },
        setShowReviewPopupActive(state, payload) {
            state.showReviewPopupActive = payload;
        },
        setReviewsCount(state, payload) {
            state.reviewsCount = payload;
        }
    },
    getters: {
        reviews: (state) => state.reviews,
        loadingReviewsTable: (state) => state.loadingReviewsTable,
        reviewToShow: (state) => state.reviewToShow,
        showReviewPopupActive: (state) => state.showReviewPopupActive,
        reviewsCount: (state) => state.reviewsCount
    }
};
