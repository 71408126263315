import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';

import app from './modules/app';
import signup from './modules/signup';
import user from './modules/user';
import dashboard from './modules/dashboard';
import places from './modules/places';
import telegram from './modules/telegram';
import poster from './modules/poster';
import menus from './modules/menus';
import reviews from './modules/reviews';
import scenario from './modules/scenarios';
import externalLinks from './modules/external-links';
import licenses from './modules/licenses';
import locations from './modules/locations';
import employees from './modules/employees';

Vue.use(Vuex);

export default createStore(Vuex.Store, {
    //выключить на проде!
    strict: true,
    modules: {
        app,
        signup,
        user,
        dashboard,
        places,
        telegram,
        poster,
        menus,
        reviews,
        scenario,
        externalLinks,
        licenses,
        locations,
        employees
    }
});
