import Axios from 'axios';

export default {
    state: {
        places: [],
        createPlacePopupActive: false,
        editPlacePopupActive: false,
        choosePrintTypePopupActive: false,
        exportQrPopupActive: false,
        loadingPlacesTable: false,
        editablePlace: {
            name: null,
            uuid: null
        },
        selectedPlaces: []
    },
    actions: {
        getPlaces(ctx, locationUuid) {
            let params = '';
            if (locationUuid) {
                params = '?location_uuid=' + locationUuid;
            }
            return Axios.get('/v1/places' + params).then((response) => {
                ctx.commit(
                    'setPlaces',
                    _.filter(response.data.data, (item) => item.type === 'qr')
                );
            });
        },
        putPlace(ctx) {
            return Axios.put('/v1/places/' + ctx.getters.editablePlace.uuid, {
                name: ctx.getters.editablePlace.name
            });
        },
        postPlace(ctx, data) {
            return Axios.post('/v1/places/', data);
        },
        deletePlace(ctx, data) {
            return Axios.delete('/v1/places/' + data.uuid);
        },
        getPlaceInfo(ctx, uuid) {
            return Axios.get(`/v1/places/${uuid}`);
        }
    },
    mutations: {
        setPlaces(state, payload) {
            state.places = payload;
        },
        setCreatePlacePopupActive(state, payload) {
            state.createPlacePopupActive = payload;
        },
        setEditPlacePopupActive(state, payload) {
            state.editPlacePopupActive = payload;
        },
        setChoosePrintTypePopupActive(state, payload) {
            state.choosePrintTypePopupActive = payload;
        },
        setExportQrPopupActive(state, payload) {
            state.exportQrPopupActive = payload;
        },
        setLoadingPlacesTable(state, payload) {
            state.loadingPlacesTable = payload;
        },
        setEditablePlace(state, payload) {
            state.editablePlace.name = payload.name;
            state.editablePlace.uuid = payload.uuid;
        },
        setNameForEditablePlace(state, payload) {
            state.editablePlace.name = payload;
        },
        setSelectedPlaces(state, payload) {
            state.selectedPlaces = payload;
        }
    },
    getters: {
        places: (state) => state.places,
        createPlacePopupActive: (state) => state.createPlacePopupActive,
        editPlacePopupActive: (state) => state.editPlacePopupActive,
        choosePrintTypePopupActive: (state) => state.choosePrintTypePopupActive,
        exportQrPopupActive: (state) => state.exportQrPopupActive,
        loadingPlacesTable: (state) => state.loadingPlacesTable,
        editablePlace: (state) => state.editablePlace,
        selectedPlaces: (state) => state.selectedPlaces
    }
};
