import Axios from 'axios';

export default {
    state: {
        locations: [],
        locationLicenseStatus: null,
        locationLoading: true,
        currentLocation: null,
        editableLocation: {
            uuid: null,
            name: null,
            locale: null,
            social: null
        },
        editLocationsPopupActive: false,
        createLocationPopupActive: false,
        loadingLocationsTable: false
    },
    actions: {
        getLocations(ctx) {
            return Axios.get('/v1/locations').then((response) => {
                ctx.commit('setLocations', response.data);
                return response;
            });
        },
        getLocationInfo(ctx, data) {
            return Axios.get('/v1/locations/' + data.uuid).then((response) => {
                ctx.commit('setReviewsCount', response.data.data.reviews_count);
                if (response.data.data?.license) {
                    ctx.commit(
                        'setLocationLicenseStatus',
                        response.data.data.license
                    );
                }
            });
        },
        postLocation(ctx, data) {
            return Axios.post('/v1/locations', {
                name: data.name,
                locale: data.locale,
                social: data.social
            });
        },
        putLocation(ctx) {
            return Axios.put(
                '/v1/locations/' + ctx.getters.editableLocation.uuid,
                {
                    name: ctx.getters.editableLocation.name,
                    locale: ctx.getters.editableLocation.locale,
                    social: ctx.getters.editableLocation.social
                }
            );
        },
        deleteLocation(ctx, data) {
            return Axios.delete('/v1/locations/' + data.uuid);
        }
    },
    mutations: {
        setLocations(state, payload) {
            state.locations = payload.data;
        },
        setLocationLicenseStatus(state, payload) {
            state.locationLicenseStatus = payload;
        },
        setLocationLoading(state, payload) {
            state.locationLoading = payload;
        },
        setCurrentLocation(state, payload) {
            state.currentLocation = payload;
            localStorage.setItem('locationUuid', payload.uuid);
        },
        setEditableLocation(state, payload) {
            state.editableLocation.uuid = payload.uuid;
            state.editableLocation.name = payload.name;
            state.editableLocation.locale = payload.locale;
            state.editableLocation.social = payload.social;
        },
        setEditLocationsPopupActive(state, payload) {
            state.editLocationsPopupActive = payload;
        },
        setNameForEditableLocation(state, payload) {
            state.editableLocation.name = payload;
        },
        setLocaleForEditableLocation(state, payload) {
            state.editableLocation.locale = payload;
        },
        setSocialForEditableLocation(state, payload) {
            state.editableLocation.social = payload;
        },
        setLoadingLocationsTable(state, payload) {
            state.loadingLocationsTable = payload;
        },
        setCreateLocationPopupActive(state, payload) {
            state.createLocationPopupActive = payload;
        }
    },
    getters: {
        locations: (state) => state.locations,
        locationLicenseStatus: (state) => state.locationLicenseStatus,
        locationLoading: (state) => state.locationLoading,
        currentLocation: (state) => state.currentLocation,
        locationUuid: (state) => {
            return state.currentLocation ? state.currentLocation.uuid : null;
        },
        locationByUuid: (state) => (uuid) => {
            return state.locations.filter((location) => location.uuid === uuid);
        },
        editableLocation: (state) => state.editableLocation,
        editLocationsPopupActive: (state) => state.editLocationsPopupActive,
        loadingLocationsTable: (state) => state.loadingLocationsTable,
        createLocationPopupActive: (state) => state.createLocationPopupActive
    }
};
