<template>
    <v-app>
        <notifications
            animation-type="velocity"
            group="app"
            position="bottom right"
        />
        <router-view />
    </v-app>
</template>

<script>
import { isMobile } from '@/base';

export default {
    methods: {
        resizeHandler() {
            if (!document.hidden) {
                this.$store.commit(
                    'setDevice',
                    isMobile() ? 'mobile' : 'desktop'
                );
            }
        }
    },
    beforeMount() {
        window.addEventListener('resize', this.resizeHandler);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    created() {
        if (isMobile()) {
            this.$store.commit('setDevice', 'mobile');
            this.$store.commit('setNavigation', false);
        }
    }
};
</script>
