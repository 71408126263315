import Axios from 'axios';
import { convertToFormData, generateGetUrl } from '../../base';
import _ from 'lodash';

export default {
    state: {
        menus: [],
        loadingMenusTable: false,
        chooseMenuTypePopupActive: false,
        menuUuid: null,
        menuName: null,
        menuMap: [],
        isMenuMapSaveButtonActive: false,
        menuMakerActiveTab: null,
        menuCurrencyUuid: null,

        //categories
        categories: [],
        loadingCategoriesTable: false,
        createCategoryPopupActive: false,
        editCategoryPopupActive: false,
        editableCategory: {
            uuid: null,
            name: null,
            active: true
        },

        //category_groups
        createCategoryGroupPopupActive: false,
        editCategoryGroupPopupActive: false,
        editableCategoryGroup: {
            uuid: null,
            name: null,
            selectedCategories: [],
            active: true
        },

        //items
        menuItems: [],
        loadingMenuItemsTable: false,
        createMenuItemPopupActive: false
    },
    actions: {
        getMenus(ctx) {
            return Axios.get('/v1/menus').then((response) => {
                ctx.commit('setMenus', response.data.data);
            });
        },
        postMenu(ctx, data) {
            return Axios.post('/v1/menus', data);
        },
        putMenu(ctx, data) {
            if (data instanceof FormData) {
                return Axios.post('/v1/menus/' + data.get('uuid'), data);
            } else {
                return Axios.put('/v1/menus/' + data.uuid, data);
            }
        },
        getMenu(ctx, data) {
            return Axios.get('/v1/menus/' + data.uuid + '/edit').then(
                (response) => {
                    ctx.commit('setMenuName', response.data.data.name);
                    ctx.commit('setMenuMap', response.data.data.position_map);
                    ctx.commit(
                        'setMenuCurrencyUuid',
                        response.data.data.currency_uuid
                    );
                }
            );
        },
        deleteMenu(ctx, data) {
            return Axios.delete('/v1/menus/' + data.uuid);
        },

        //categories
        getCategories(ctx, data) {
            let url = generateGetUrl('/v1/menu_categories', data);
            return Axios.get(url).then((response) => {
                ctx.commit('setCategories', response.data.data);
            });
        },
        postCategory(ctx, data) {
            return Axios.post('/v1/menu_categories', data);
        },
        putCategory(ctx, data) {
            return Axios.put('/v1/menu_categories/' + data.uuid, data);
        },
        deleteCategory(ctx, data) {
            return Axios.delete('/v1/menu_categories/' + data.uuid);
        },

        //items
        getMenuItems(ctx, data) {
            let url = generateGetUrl('/v1/menu_items', data);
            return Axios.get(url).then((respone) => {
                ctx.commit('setMenuItems', respone.data.data);
            });
        },
        postMenuItem(ctx, data) {
            return Axios.post('/v1/menu_items', data);
        },
        postMenuItemFile(ctx, data) {
            let formData = convertToFormData({
                file: data.file
            });
            return Axios.post(
                '/v1/menu_items/' + data.uuid + '/upload',
                formData
            );
        },
        putMenuItem(ctx, data) {
            return Axios.put('/v1/menu_items/' + data.uuid, data);
        },
        deleteMenuItem(ctx, data) {
            return Axios.delete('/v1/menu_items/' + data.uuid);
        }
    },
    mutations: {
        setMenus(state, payload) {
            state.menus = payload;
        },
        setLoadingMenusTable(state, paylaod) {
            state.loadingMenusTable = paylaod;
        },
        setChooseMenuTypePopupActive(state, payload) {
            state.chooseMenuTypePopupActive = payload;
        },
        setMenuUuid(state, payload) {
            state.menuUuid = payload;
        },

        setMenuMakerActiveTab(state, payload) {
            state.menuMakerActiveTab = payload;
        },

        //overview
        setMenuName(state, payload) {
            state.menuName = payload;
        },
        setMenuMap(state, payload) {
            state.menuMap = payload;
        },
        setMenuCurrencyUuid(state, payload) {
            state.menuCurrencyUuid = payload;
        },
        setIsMenuMapSaveButtonActive(state, payload) {
            state.isMenuMapSaveButtonActive = payload;
        },

        //categories
        setCategories(state, payload) {
            state.categories = payload;
        },
        setLoadingCategoriesTable(state, payload) {
            state.loadingCategoriesTable = payload;
        },
        setCreateCategoryPopupActive(state, payload) {
            state.createCategoryPopupActive = payload;
        },
        setEditCategoryPopupActive(state, payload) {
            state.editCategoryPopupActive = payload;
        },
        setEditableCategory(state, payload) {
            state.editableCategory = payload;
        },
        setEditableCategoryByProperty(state, payload) {
            if (Object.hasOwn(state.editableCategory, payload.property)) {
                state.editableCategory[payload.property] = payload.value;
            }
        },
        resetEditableCategory(state) {
            state.editableCategory.uuid = null;
            state.editableCategory.name = null;
            state.editableCategory.active = true;
            state.editableCategory.selectedCategories = [];
        },

        //category_group
        setCreateCategoryGroupPopupActive(state, payload) {
            state.createCategoryGroupPopupActive = payload;
        },
        setEditCategoryGroupPopupActive(state, payload) {
            state.editCategoryGroupPopupActive = payload;
        },
        setEditableCategoryGroup(state, payload) {
            state.editableCategoryGroup = payload;
        },
        setEditableCategoryGroupByProperty(state, payload) {
            if (Object.hasOwn(state.editableCategoryGroup, payload.property)) {
                state.editableCategoryGroup[payload.property] = payload.value;
            }
        },
        resetEditableCategoryGroup(state, payload) {
            state.editableCategory.uuid = null;
            state.editableCategory.name = null;
            state.editableCategory.active = true;
        },

        //menu items
        setMenuItems(state, payload) {
            state.menuItems = payload;
        },
        setLoadingMenuItemsTable(state, payload) {
            state.loadingMenuItemsTable = payload;
        },
        setCreateMenuItemPopupActive(state, payload) {
            state.createMenuItemPopupActive = payload;
        }
    },
    getters: {
        menus: (state) => state.menus,
        loadingMenusTable: (state) => state.loadingMenusTable,
        chooseMenuTypePopupActive: (state) => state.chooseMenuTypePopupActive,
        menuUuid: (state) => state.menuUuid,
        menuMap: (state) => state.menuMap,
        menuName: (state) => state.menuName,
        isMenuMapSaveButtonActive: (state) => state.isMenuMapSaveButtonActive,
        menuMakerActiveTab: (state) => state.menuMakerActiveTab,
        menuCurrencyUuid: (state) => state.menuCurrencyUuid,

        //categories
        categories: (state) =>
            _.filter(state.categories, (category) => !category.parent),
        loadingCategoriesTable: (state) => state.loadingCategoriesTable,
        createCategoryPopupActive: (state) => state.createCategoryPopupActive,
        editCategoryPopupActive: (state) => state.editCategoryPopupActive,
        editableCategory: (state) => state.editableCategory,

        //category_groups
        categoryGroups: (state) =>
            _.filter(state.categories, (category) => category.parent),
        createCategoryGroupPopupActive: (state) =>
            state.createCategoryGroupPopupActive,
        editCategoryGroupPopupActive: (state) =>
            state.editCategoryGroupPopupActive,
        editableCategoryGroup: (state) => state.editableCategoryGroup,

        //menu items
        menuItems: (state) => state.menuItems,
        loadingMenuItemsTable: (state) => state.loadingMenuItemsTable,
        createMenuItemPopupActive: (state) => state.createMenuItemPopupActive
    }
};
