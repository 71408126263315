import Axios from 'axios';

export default {
    state: {
        login: null,
        password: null
    },
    actions: {
        signup(ctx, credentials) {
            return Axios.post('/v1/register', credentials).then(() => {
                ctx.commit('setLogin', credentials.login);
                ctx.commit('setPassword', credentials.password);
            });
        }
    },
    mutations: {
        setLogin(state, login) {
            state.login = login;
        },
        setPassword(state, password) {
            state.password = password;
        }
    },
    getters: {
        getLogin: (state) => state.login,
        getPassword: (state) => state.password
    }
};
