import Axios from 'axios';

export default {
    state: {
        telegramChannels: [],
        loadingTelegramChannelsTable: false,
        loadingChannelSubscribersTable: false,
        createTelegramChannelPopupActive: false,
        editTelegramChannelPopupActive: false,
        subscribeTelegramPopupActive: false,
        channelSubscribersPopupActive: false,
        urlToSubscribe: null,
        botCommand: null,
        channelSubscribers: [],
        selectedChannel: {
            uuid: null,
            name: null
        }
    },
    actions: {
        getTelegramChannels(ctx) {
            return Axios.get('/api/telegram_channels').then((response) => {
                ctx.commit('setTelegramChannels', response.data.data);
            });
        },
        postTelegramChannel(ctx, data) {
            return Axios.post('/api/telegram_channels', {
                name: data.name
            });
        },
        putTelegramChannel(ctx, data) {
            return Axios.put('/api/telegram_channels/' + data.uuid, {
                name: data.name
            });
        },
        deleteTelegramChannel(ctx, data) {
            return Axios.delete('/api/telegram_channels/' + data.uuid);
        },
        getUrlToSubscribe(ctx, data) {
            return Axios.get(
                '/api/telegram_channels/' + data.uuid + '/edit'
            ).then((response) => {
                ctx.commit(
                    'setUrlToSubscribe',
                    response.data.data.add_subscriber_url
                );
                ctx.commit('setBotCommand', response.data.data.add_group_url);
            });
        },
        getTelegramSubscribers(ctx, data) {
            let params = '';
            if (data?.uuid) {
                params = '?telegram_channel_uuid=' + data.uuid;
            }
            return Axios.get('/api/telegram_subscribers' + params).then(
                (response) => {
                    ctx.commit('setChannelSubscribers', response.data.data);
                }
            );
        },
        deleteTelegramSubscriber(ctx, data) {
            return Axios.delete('/api/telegram_subscribers/' + data.uuid);
        }
    },
    mutations: {
        setTelegramChannels(state, payload) {
            state.telegramChannels = payload;
        },
        setLoadingTelegramChannelsTable(state, payload) {
            state.loadingTelegramChannelsTable = payload;
        },
        setLoadingChannelSubscribersTable(state, payload) {
            state.loadingChannelSubscribersTable = payload;
        },
        setCreateTelegramChannelPopupActive(state, payload) {
            state.createTelegramChannelPopupActive = payload;
        },
        setEditTelegramChannelPopupActive(state, payload) {
            state.editTelegramChannelPopupActive = payload;
        },
        setSubscribeTelegramPopupActive(state, payload) {
            state.subscribeTelegramPopupActive = payload;
        },
        setChannelSubscribersPopupActive(state, payload) {
            state.channelSubscribersPopupActive = payload;
        },
        setUrlToSubscribe(state, payload) {
            state.urlToSubscribe = payload;
        },
        setBotCommand(state, payload) {
            state.botCommand = payload;
        },
        setChannelSubscribers(state, payload) {
            state.channelSubscribers = payload;
        },
        setSelectedChannel(state, payload) {
            state.selectedChannel = payload;
        },
        setSelectedChannelName(state, payload) {
            state.selectedChannel.name = payload;
        }
    },
    getters: {
        telegramChannels: (state) => state.telegramChannels,
        loadingTelegramChannelsTable: (state) =>
            state.loadingTelegramChannelsTable,
        loadingChannelSubscribersTable: (state) =>
            state.loadingChannelSubscribersTable,
        createTelegramChannelPopupActive: (state) =>
            state.createTelegramChannelPopupActive,
        editTelegramChannelPopupActive: (state) =>
            state.editTelegramChannelPopupActive,
        subscribeTelegramPopupActive: (state) =>
            state.subscribeTelegramPopupActive,
        channelSubscribersPopupActive: (state) =>
            state.channelSubscribersPopupActive,
        urlToSubscribe: (state) => state.urlToSubscribe,
        botCommand: (state) => state.botCommand,
        channelSubscribers: (state) => state.channelSubscribers,
        selectedChannel: (state) => state.selectedChannel
    }
};
