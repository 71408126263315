import Axios from 'axios';
import { generateGetUrl } from '../../base';

export default {
    state: {
        externalLinks: [],
        loadingExternalLinksTable: false,
        externalLinksPopupActive: false,
        editableExternalLink: null
    },
    actions: {
        getExternalLinks(ctx, data) {
            const url = generateGetUrl('/v1/places', data);
            return Axios.get(url).then((response) => {
                ctx.commit(
                    'setExternalLinks',
                    _.filter(response.data.data, (item) => item.type === 'web')
                );
            });
        },
        postExternalLink: (ctx, data) => {
            return Axios.post('/v1/places/', data);
        },
        putExternalLink(ctx, data) {
            return Axios.put(`/v1/places/${data.uuid}`, {
                name: data.name
            });
        }
    },
    mutations: {
        setExternalLinks: (state, payload) => (state.externalLinks = payload),
        setLoadingExternalLinksTable: (state, payload) =>
            (state.loadingExternalLinksTable = payload),
        setExternalLinksPopupActive: (state, payload) =>
            (state.externalLinksPopupActive = payload),
        setEditableExternalLink: (state, payload) =>
            (state.editableExternalLink = payload)
    },
    getters: {
        externalLinks: (state) => state.externalLinks,
        loadingExternalLinksTable: (state) => state.loadingExternalLinksTable,
        externalLinksPopupActive: (state) => state.externalLinksPopupActive,
        editableExternalLink: (state) => state.editableExternalLink
    }
};
