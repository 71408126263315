import Vue from 'vue';
import App from '@/App';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import VueAxios from 'vue-axios';
import Axios from 'axios';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueSocialauth from 'vue-social-auth';

import vuetify from '@/plugins/vuetify';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import moment from 'moment';
import VueFlags from '@growthbunker/vueflags';
import { directive as onClickaway } from 'vue-clickaway';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import config from '@/config.json';
import '@/css/app.scss';

const {
    VUE_APP_GAUTH_CLIENT_ID,
    VUE_APP_GAUTH_REDIRECT_URI,
    VUE_APP_FACEBOOK_CLIENT_ID,
    VUE_APP_FACEBOOK_REDIRECT_URI,
    VUE_APP_SENTRY_DSN,
    VUE_APP_API_URL
} = config;

export const eventBus = new Vue();

Vue.use(Notifications, { velocity });
Vue.use(VueLodash, { name: 'custom', lodash: lodash });
Vue.use(VueFlags, {
    iconPath: '/flags/'
});
Vue.use(VueSocialauth, {
    providers: {
        google: {
            clientId: VUE_APP_GAUTH_CLIENT_ID,
            redirectUri: VUE_APP_GAUTH_REDIRECT_URI
        },
        facebook: {
            clientId: VUE_APP_FACEBOOK_CLIENT_ID,
            redirectUri: VUE_APP_FACEBOOK_REDIRECT_URI
        }
    }
});
Vue.use(VueAxios, Axios);

Vue.directive('on-clickaway', onClickaway);

Vue.prototype.$moment = moment;

Sentry.init({
    dsn: VUE_APP_SENTRY_DSN,
    integrations: [
        new VueIntegration({
            Vue,
            tracing: true,
            tracingOptions: {
                trackComponents: true
            }
        }),
        new Integrations.BrowserTracing({
            tracingOrigins: [VUE_APP_API_URL]
        })
    ],
    tracesSampleRate: 1.0
});

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    i18n,
    created() {
        const token = localStorage.getItem('token');
        const locale = localStorage.getItem('locale');

        if (token) {
            this.$store.commit('setToken', JSON.parse(token));
        }
        if (locale) {
            this.$store.commit('setLocale', JSON.parse(locale));
        }

        Axios.defaults.baseURL = VUE_APP_API_URL;
        Axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    this.$store.commit('clearToken');
                    this.$router.push('/login');
                }
                if (error.response?.data) {
                    let message = '';
                    for (let key in error.response.data) {
                        if (key !== 'trace') {
                            message +=
                                key + ': ' + error.response.data[key] + '\n';
                        }
                    }
                    Sentry.addBreadcrumb({
                        level: Sentry.Severity.Info,
                        category: 'xhrResponse',
                        message
                    });
                }
                Sentry.captureException(error);
                return Promise.reject(error);
            }
        );
    },
    render: (h) => h(App)
});
