import Axios from 'axios';

export default {
    state: {
        licenses: [],
        plans: [],
        editableLicense: {
            uuid: null,
            quantity: null,
            locationsList: [],
            locations: [],
            status: null
        },
        renewableLicense: {
            uuid: null
        },
        paymentService: null,
        buyLicensePopupActive: false,
        editLicensePopupActive: false,
        prolongLicensePopupActive: false,
        loadingTable: false,
        publicContractPopupActive: false
    },
    actions: {
        getLicenses(ctx) {
            return Axios.get('/v1/licenses').then((response) => {
                ctx.commit('setLicenses', response.data.data);
            });
        },
        getPlans(ctx) {
            return Axios.get('/v1/licenses/create').then((response) => {
                ctx.commit(
                    'setPlans',
                    response.data.data.plans.map((item) => {
                        item['quantity'] = 1;
                        return item;
                    })
                );
            });
        },
        postLicense(ctx, data) {
            return Axios.post('/v1/licenses', data);
        },
        editLicense(ctx, uuid) {
            return Axios.get(`/v1/licenses/${uuid}/edit`).then((response) => {
                const data = response.data.data;
                ctx.commit('setEditableLicense', data);
            });
        },
        putLicense(ctx) {
            return Axios.put(
                `/v1/licenses/${ctx.getters.editableLicense.uuid}`,
                {
                    locations: ctx.getters.editableLicense.locations.map(
                        (item) => {
                            return item.uuid ? item.uuid : item;
                        }
                    )
                }
            );
        },
        paidLicense(ctx, data) {
            return Axios.post('/v1/licenses/paid', data).then((response) => {
                ctx.commit('setPaymentService', response.data.data);
            });
        },
        unsubscribeLicense(ctx, data) {
            return Axios.post('/v1/licenses/unsubscribe', data).then(
                (response) => {
                    ctx.commit('setPaymentService', response.data.data);
                }
            );
        },
        deleteLicense(ctx, uuid) {
            return Axios.delete(`/v1/licenses/${uuid}`);
        }
    },
    mutations: {
        setLicenses(state, payload) {
            state.licenses = payload;
        },
        setPlans(state, payload) {
            state.plans = payload;
        },
        changeLicenseQuantity(state, payload) {
            const plan = _.find(state.plans, (plan) => {
                return plan.uuid === payload.uuid;
            });
            plan.quantity = payload.quantity;
        },
        setEditableLicense(state, payload) {
            state.editableLicense = {
                uuid: payload.license.uuid,
                quantity: payload.license.quantity,
                locations: payload.license.locations,
                locationsList: payload.locations,
                status: payload.license.status
            };
        },
        setRenewableLicense(state, payload) {
            state.renewableLicense = {
                uuid: payload.uuid
            };
        },
        setLocationsForEditableLicense(state, payload) {
            state.editableLicense.locations = payload;
        },
        setLocationsList(state, payload) {
            state.editableLicense.locationsList = payload;
        },
        setPaymentService(state, payload) {
            state.paymentService = payload;
        },
        setBuyLicensePopupActive(state, payload) {
            state.buyLicensePopupActive = payload;
        },
        setEditLicensePopupActive(state, payload) {
            state.editLicensePopupActive = payload;
        },
        setProlongLicensePopupActive(state, payload) {
            state.prolongLicensePopupActive = payload;
        },
        setLoadingTable(state, payload) {
            state.loadingTable = payload;
        },
        setPublicContractPopupActive(state, payload) {
            state.publicContractPopupActive = payload;
        }
    },
    getters: {
        licenses: (state) => state.licenses,
        plans: (state) => state.plans,
        editableLicense: (state) => state.editableLicense,
        renewableLicense: (state) => state.renewableLicense,
        paymentService: (state) => state.paymentService,
        buyLicensePopupActive: (state) => state.buyLicensePopupActive,
        editLicensePopupActive: (state) => state.editLicensePopupActive,
        prolongLicensePopupActive: (state) => state.prolongLicensePopupActive,
        loadingTable: (state) => state.loadingTable,
        publicContractPopupActive: (state) => state.publicContractPopupActive
    }
};
