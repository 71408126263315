import Axios from 'axios';
import { generateGetUrl } from '../../base';

export default {
    state: {
        employees: [],
        loadingEmployeesTable: false,
        employeePopupActive: false,
        editableEmployee: null,
        employeeEditState: false,
        employeeSubscribePopupActive: false,
        employeeUrlToSubscribe: null,
        employeeTablesPopupActive: false,
        employeeSelectedTables: null
    },
    actions: {
        getEmployees(ctx, data) {
            const url = generateGetUrl('/v1/employees', data);
            return Axios.get(url).then((response) => {
                ctx.commit('setEmployees', response.data.data);
            });
        },
        postEmployee(ctx, data) {
            return Axios.post('/v1/employees', {
                name: data.name,
                location_uuid: data.locationUuid
            });
        },
        putEmployee(ctx, data) {
            return Axios.put(`/v1/employees/${data.uuid}`, {
                name: data.name
            });
        },
        getEmployeesForEdit(ctx, data) {
            return Axios.get(`/v1/employees/${data.uuid}/edit`).then(
                (response) => {
                    ctx.commit(
                        'setEmployeeUrlToSubscribe',
                        response.data.data.add_subscriber_url
                    );
                    ctx.commit(
                        'setEmployeeSelectedTables',
                        response.data.data.places
                    );
                }
            );
        },
        updateEmployeePlaces(ctx, data) {
            return Axios.put(`/v1/employees/${data.uuid}/places`, {
                places: data.places
            });
        },
        deleteEmployee(ctx, data) {
            return Axios.delete(`/v1/employees/${data.uuid}`);
        }
    },
    mutations: {
        setEmployees: (state, payload) => (state.employees = payload),
        setLoadingEmployeesTable: (state, payload) =>
            (state.loadingEmployeesTable = payload),
        setEmployeePopupActive: (state, payload) =>
            (state.employeePopupActive = payload),
        setEditableEmployee: (state, payload) =>
            (state.editableEmployee = payload),
        setEmployeeEditState: (state, payload) =>
            (state.employeeEditState = payload),
        setEmployeeSubscribePopupActive: (state, payload) =>
            (state.employeeSubscribePopupActive = payload),
        setEmployeeUrlToSubscribe: (state, payload) =>
            (state.employeeUrlToSubscribe = payload),
        setEmployeeTablesPopupActive: (state, payload) =>
            (state.employeeTablesPopupActive = payload),
        setEmployeeSelectedTables: (state, payload) =>
            (state.employeeSelectedTables = payload)
    },
    getters: {}
};
