import Vue from 'vue';
import VueRouter from 'vue-router';
import * as Sentry from '@sentry/browser';
/*import Main from '../views/Main';
 import Login from '../views/Login';
 import Signup from '../views/Signup';
 import CompleteRegistration from '../views/CompleteRegistration';
 import Auth from '../views/auth/Auth';
 import Dashboard from '../views/dashboard';
 import Places from '../views/places';
 import Apps from '../views/apps';
 import Telegram from '../views/apps/Telegram';
 import Poster from '../views/apps/Poster';
 import PosterAccounts from '../views/apps/Poster/components/AccountList';
 import PosterAccount from '../views/apps/Poster/components/account/Account';
 import Menus from '../views/menus';
 import MenuMaker from '../views/menumaker';
 import Reviews from '../views/reviews';
 import Scenario from '../views/scenarios';
 import ScenarioHome from '../views/scenarios/Home';
 import ScenarioCreate from '../views/scenarios/Create';
 import ExternalLinks from '../views/external-links';
 import ExternalLinksList from '../views/external-links/components/table/ExternalLinks';
 import Licenses from '../views/licenses';
 import Locations from '../views/locations';
 import About from '../views/about';
 import NotFound from '../components/layout/NotFound';*/

Vue.use(VueRouter);

function handleChunkLoadError(importPromise) {
    return importPromise.catch((error) => {
        Sentry.captureException(error);
        /* setTimeout(()=>{
         window.location.reload()
         }, 2000);*/

        return error;
    });
}

const Main = () => handleChunkLoadError(import('@/views/Main'));
const Login = () => handleChunkLoadError(import('@/views/Login'));
const Signup = () => handleChunkLoadError(import('@/views/Signup'));
const CompleteRegistration = () =>
    handleChunkLoadError(import('@/views/CompleteRegistration'));
const Auth = () => handleChunkLoadError(import('@/views/auth/Auth'));
const Dashboard = () => handleChunkLoadError(import('@/views/dashboard'));
const Places = () => handleChunkLoadError(import('@/views/places'));
const Apps = () => handleChunkLoadError(import('@/views/apps'));
const Telegram = () => handleChunkLoadError(import('@/views/apps/Telegram'));
const Poster = () => handleChunkLoadError(import('@/views/apps/Poster'));
const PosterAccounts = () =>
    handleChunkLoadError(import('@/views/apps/Poster/components/AccountList'));
const PosterAccount = () =>
    handleChunkLoadError(
        import('@/views/apps/Poster/components/account/Account')
    );
const Menus = () => handleChunkLoadError(import('@/views/menus'));
const MenuMaker = () => handleChunkLoadError(import('@/views/menumaker'));
const Reviews = () => handleChunkLoadError(import('@/views/reviews'));
const Scenario = () => handleChunkLoadError(import('@/views/scenarios'));
const ScenarioHome = () =>
    handleChunkLoadError(import('@/views/scenarios/Home'));
const ScenarioCreate = () =>
    handleChunkLoadError(import('@/views/scenarios/Create'));
const ExternalLinks = () =>
    handleChunkLoadError(import('@/views/external-links'));
const ExternalLinksList = () =>
    handleChunkLoadError(
        import('@/views/external-links/components/table/ExternalLinks')
    );
const Licenses = () => handleChunkLoadError(import('@/views/licenses'));
const Locations = () => handleChunkLoadError(import('@/views/locations'));
const About = () => handleChunkLoadError(import('@/views/about'));
const Terms = () => handleChunkLoadError(import('@/views/terms'));
const NotFound = () =>
    handleChunkLoadError(import('@/components/layout/NotFound'));

/*const Terms = () => handleChunkLoadError(import('../views/terms'));*/

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

export const routes = [
    {
        name: 'Main',
        path: '/',
        component: Main,
        redirect: '/dashboard',
        meta: {
            auth: true
        },
        children: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                component: Dashboard
            },
            {
                name: 'Places',
                path: '/places',
                component: Places
            },
            {
                name: 'Apps',
                path: '/apps',
                component: Apps
            },
            {
                name: 'Telegram',
                path: '/apps/telegram',
                component: Telegram
            },
            {
                name: 'Poster',
                path: '/apps/poster',
                component: Poster,
                redirect: '/apps/poster/accounts',
                children: [
                    {
                        name: 'PosterAccounts',
                        path: 'accounts',
                        component: PosterAccounts
                    },
                    {
                        name: 'PosterAccount',
                        path: 'account/:uuid',
                        component: PosterAccount,
                        props: (route) => ({ uuid: route.params.uuid })
                    }
                ]
            },
            {
                name: 'Menus',
                path: '/menus',
                component: Menus
            },
            {
                name: 'MenuMaker',
                path: '/menumaker/:uuid',
                component: MenuMaker,
                props: (route) => ({ uuid: route.params.uuid })
            },
            {
                name: 'Review',
                path: '/reviews',
                component: Reviews
            },
            {
                path: '/scenario',
                component: Scenario,
                children: [
                    {
                        name: 'ScenarioHome',
                        path: '',
                        component: ScenarioHome
                    },
                    {
                        name: 'ScenarioCreate',
                        path: 'create',
                        component: ScenarioCreate
                    },
                    {
                        name: 'ScenarioEdit',
                        path: 'edit',
                        component: ScenarioCreate
                    }
                ]
            },
            {
                path: '/external-links',
                component: ExternalLinks,
                redirect: {
                    name: 'ExternalLinksList'
                },
                children: [
                    {
                        name: 'ExternalLinksList',
                        path: '',
                        component: ExternalLinksList
                    },
                    {
                        name: 'ExternalScenario',
                        path: ':placeUuid/interface',
                        component: Scenario,
                        props: true,
                        redirect: {
                            name: 'ExternalScenarioHome'
                        },
                        children: [
                            {
                                name: 'ExternalScenarioHome',
                                path: '',
                                component: ScenarioHome
                            },
                            {
                                name: 'ExternalScenarioCreate',
                                path: 'create',
                                component: ScenarioCreate
                            },
                            {
                                name: 'ExternalScenarioEdit',
                                path: 'edit',
                                component: ScenarioCreate
                            }
                        ]
                    }
                ]
            },
            {
                name: 'Licenses',
                path: '/licenses',
                component: Licenses
            },
            {
                name: 'Locations',
                path: '/locations',
                component: Locations
            },
            /*    {
             name: 'Employees',
             path: '/employees',
             component: Employees
             },*/
            {
                name: 'About',
                path: '/about',
                component: About
            }
        ]
    },
    {
        name: 'Login',
        path: '/login',
        component: Login
    },
    {
        name: 'Signup',
        path: '/signup',
        component: Signup
    },
    {
        name: 'CompleteRegistration',
        path: '/complete-registration',
        component: CompleteRegistration
    },
    {
        name: 'Auth',
        path: '/auth/:provider/callback',
        component: Auth,
        props: (route) => ({ provider: route.params.provider })
    },
    {
        name: 'Terms',
        path: '/terms',
        component: Terms
    },
    {
        path: '/404',
        name: '404',
        component: NotFound
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('token');

    if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
        next('/login');
        return;
    }
    if (
        to.matched.some(
            (record) => record.name === 'Login' || record.name === 'Signup'
        ) &&
        loggedIn
    ) {
        next('/');
        return;
    }
    next();
});

export default router;
