import Axios from 'axios';
import i18n from '../../i18n';
import { capitalize, generateGetUrl } from '../../base';

const scenarioDefault = {
    name: null,
    name_for_guests: null,
    description_for_guests: null,
    background: null,
    theme_styles: {
        color: null
    },
    social_active: false,
    menu_active: false,
    menu_uuid: null,
    menu_url: null,
    menu_cart: false,
    menu_order_types: [],
    menu_order_telegram_channel_uuid: null,
    call_buttons_active: false,
    call_buttons: [],
    review_active: false,
    review_anonymous: true,
    review_telegram_channel_uuid: null,
    custom_buttons_active: false,
    custom_buttons: []
};

export default {
    state: {
        scenarioUuid: null,
        menusForScenario: [],
        channelsForScenario: [],
        scenario: scenarioDefault,
        activateButtonPopup: false,
        createCallButtonPopup: false,
        editCallButtonPopup: false,
        scenarioType: 'qr',
        customButtonPopup: false,
        editableCustomButton: null
    },
    actions: {
        createScenario(ctx, data) {
            const url = generateGetUrl('/v1/scenarios/create', data);
            return Axios.get(url).then((response) => {
                ctx.commit('setMenusForScenario', response.data.data.menus);
                ctx.commit(
                    'setChannelsForScenario',
                    response.data.data.channels
                );
            });
        },
        getScenarioById(ctx, scenarioUuid) {
            return Axios.get('/v1/scenarios/' + scenarioUuid + '/edit').then(
                (response) => {
                    ctx.commit('setMenusForScenario', response.data.data.menus);
                    ctx.commit(
                        'setChannelsForScenario',
                        response.data.data.channels
                    );
                    ctx.commit('setScenario', response.data.data.scenario);
                    ctx.commit(
                        'setScenarioUuid',
                        response.data.data.scenario.uuid
                    );
                }
            );
        },
        putScenario(ctx, data) {
            return Axios.put(
                '/v1/scenarios/' + data.scenarioUuid,
                data.scenario
            );
        },
        postScenario(ctx, data) {
            return Axios.post('/v1/scenarios', data);
        }
    },
    mutations: {
        setScenarioUuid(state, payload) {
            state.scenarioUuid = payload;
        },
        setScenario(state, payload) {
            state.scenario = payload;
        },
        clearScenario(state) {
            state.scenario = scenarioDefault;
        },
        setScenarioProperty(state, payload) {
            if (Object.hasOwn(state.scenario, payload.property)) {
                state.scenario[payload.property] = payload.value;
            }
        },
        setScenarioThemeProperty(state, payload) {
            state.scenario.theme_styles[payload.property] = payload.value;
        },
        pushOrderType(state, payload) {
            state.scenario.menu_order_types.push({
                delivery_type: payload,
                payment_type_cash: true,
                payment_type_card: true
            });
        },
        removeFromOrderType(state, payload) {
            const index = _.findIndex(
                state.scenario.menu_order_types,
                (item) => item.delivery_type === payload
            );
            if (index !== -1) {
                state.scenario.menu_order_types.splice(index, 1);
            }
        },
        setPaymentTypeValue(state, payload) {
            const item = _.find(
                state.scenario.menu_order_types,
                (item) => item.delivery_type === payload.orderType
            );
            if (item) {
                item[payload.paymentType] = payload.value;
            }
        },
        pushToCallButtons(state, payload) {
            state.scenario.call_buttons.push(payload);
        },
        editCallButton(state, payload) {
            const index = _.findIndex(state.scenario.call_buttons, (item) => {
                return item.name === payload.name;
            });
            if (index !== -1) {
                state.scenario.call_buttons[index].text = payload.text;
                state.scenario.call_buttons[index].callback_text =
                    payload.callbackText;
                state.scenario.call_buttons[index].telegram_channel_uuid =
                    payload.telegram_channel_uuid;
                state.scenario.call_buttons[index].poster_channel_uuid =
                    payload.poster_channel_uuid;
            }
        },
        sliceFromCallButtons(state, payload) {
            const index = _.findIndex(state.scenario.call_buttons, (item) => {
                return item.name === payload;
            });
            if (index !== -1) {
                state.scenario.call_buttons.splice(index, 1);
            }
        },
        setMenusForScenario(state, payload) {
            let filteredMenu = {};
            _.forEach(payload, (item) => {
                if (Object.hasOwn(filteredMenu, item.type)) {
                    filteredMenu[item.type].push(item);
                } else {
                    filteredMenu[item.type] = [item];
                }
            });
            state.menusForScenario = filteredMenu;
        },
        setChannelsForScenario(state, payload) {
            state.channelsForScenario = payload;
        },
        setActivateButtonPopup(state, payload) {
            state.activateButtonPopup = payload;
        },
        setCreateCallButtonPopup(state, payload) {
            state.createCallButtonPopup = payload;
        },
        setEditCallButtonPopup(state, payload) {
            state.editCallButtonPopup = payload;
        },
        setScenarioType(state, payload) {
            state.scenarioType = payload;
        },
        setCustomButtonPopup(state, payload) {
            state.customButtonPopup = payload;
        },
        setCustomButtons(state, payload) {
            state.scenario.custom_buttons = payload;
        },
        pushToCustomButtons(state, payload) {
            if (state.scenario.custom_buttons === null) {
                state.scenario.custom_buttons = [];
            }
            state.scenario.custom_buttons.push(payload);
        },
        spliceFromCustomButtons(state, payload) {
            const index = _.findIndex(
                state.scenario.custom_buttons,
                (button) => button.id === payload.id
            );
            if (index !== -1) {
                state.scenario.custom_buttons.splice(index, 1);
            }
        },
        editCustomButtons(state, payload) {
            const index = _.findIndex(
                state.scenario.custom_buttons,
                (button) => button.id === payload.id
            );
            if (index !== -1) {
                state.scenario.custom_buttons[index].text = payload.text;
                state.scenario.custom_buttons[index].url = payload.url;
            }
        },
        setEditableCustomButton(state, payload) {
            state.editableCustomButton = payload;
        }
    },
    getters: {
        scenarioUuid: (state) => state.scenarioUuid,
        scenario: (state) => state.scenario,
        menusForScenario: (state) => {
            let result = [];
            for (let key in state.menusForScenario) {
                result.push({
                    name: i18n.t(key),
                    uuid: key,
                    disabled: true
                });
                for (
                    let i = 0;
                    i < state.menusForScenario[key].length;
                    i += 1
                ) {
                    result.push(
                        Object.assign(state.menusForScenario[key][i], {
                            type: key
                        })
                    );
                }
            }
            return result;
        },
        channelsForScenario: (state) => {
            let array = [];
            for (let key in state.channelsForScenario) {
                if (Object.keys(state.channelsForScenario[key]).length) {
                    array.push({
                        name: capitalize(key),
                        uuid: key,
                        disabled: true
                    });
                    for (
                        let i = 0;
                        i < state.channelsForScenario[key].length;
                        i += 1
                    ) {
                        array.push(
                            Object.assign(state.channelsForScenario[key][i], {
                                type: key
                            })
                        );
                    }
                }
            }
            return array;
        },
        channelsForOrder: (state) => {
            let array = [];
            let allowedApps = ['telegram'];
            for (let key in state.channelsForScenario) {
                let appIsAllowed = allowedApps.find((item) => {
                    return item === key;
                });
                if (appIsAllowed) {
                    if (Object.keys(state.channelsForScenario[key]).length) {
                        array.push({
                            name: capitalize(key),
                            uuid: key,
                            disabled: true
                        });
                        for (
                            let i = 0;
                            i < state.channelsForScenario[key].length;
                            i += 1
                        ) {
                            array.push(
                                Object.assign(
                                    state.channelsForScenario[key][i],
                                    {
                                        type: key
                                    }
                                )
                            );
                        }
                    }
                }
            }
            return array;
        },
        isScenarioHasEmptyPaymentTypes: (state) => {
            let result = false;
            for (
                let i = 0;
                i < state.scenario.menu_order_types.length;
                i += 1
            ) {
                if (
                    !state.scenario.menu_order_types[i].payment_type_cash &&
                    !state.scenario.menu_order_types[i].payment_type_card
                ) {
                    result = true;
                }
            }
            return result;
        },
        channelsForReview: (state) => {
            let array = [];
            let allowedApps = ['telegram'];
            for (let key in state.channelsForScenario) {
                let appIsAllowed = allowedApps.find((item) => {
                    return item === key;
                });
                if (appIsAllowed) {
                    if (Object.keys(state.channelsForScenario[key]).length) {
                        array.push({
                            name: capitalize(key),
                            uuid: key,
                            disabled: true
                        });
                        for (
                            let i = 0;
                            i < state.channelsForScenario[key].length;
                            i += 1
                        ) {
                            array.push(
                                Object.assign(
                                    state.channelsForScenario[key][i],
                                    {
                                        type: key
                                    }
                                )
                            );
                        }
                    }
                }
            }
            return array;
        },
        activateButtonPopup: (state) => state.activateButtonPopup,
        createCallButtonPopup: (state) => state.createCallButtonPopup,
        editCallButtonPopup: (state) => state.editCallButtonPopup,
        scenarioType: (state) => state.scenarioType,
        customButtonPopup: (state) => state.customButtonPopup,
        editableCustomButton: (state) => state.editableCustomButton
    }
};
