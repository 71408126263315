import Axios from 'axios';
import config from '@/config.json';

const {
    VUE_APP_PLATFORM_URL,
    VUE_APP_APP_URL,
    VUE_APP_API_URL,
    VUE_APP_POSTER_APP_ID,
    VUE_APP_POSTER_REDIRECT_URI
} = config;

export default {
    state: {
        portalUrl: VUE_APP_PLATFORM_URL,
        appUrl: VUE_APP_APP_URL,
        apiUrl: VUE_APP_API_URL,
        supportBotName: '@yupa_support_bot',
        supportBotUrl: 'https://t.me/yupa_support_bot',
        senderBotName: '@yupa_sender_bot',
        instructionPopupActive: false,
        welcomePopupActive: false,
        supportPopupActive: false,
        device: 'desktop',
        navigation: false,
        posterAppId: VUE_APP_POSTER_APP_ID,
        posterRedirectUri: VUE_APP_POSTER_REDIRECT_URI,
        currencies: [],
        countriesForRegister: []
    },
    actions: {
        getCurrencies(ctx) {
            return Axios.get('/v1/currencies').then((response) => {
                ctx.commit('setCurrencies', response.data.data);
            });
        },
        getCountriesForRegister(ctx) {
            return Axios.get('/v1/countries_for_register').then((response) => {
                ctx.commit('setCountriesForRegister', response.data.data);
            });
        }
    },
    mutations: {
        setInstructionPopupActive(state, payload) {
            state.instructionPopupActive = payload;
        },
        setWelcomePopupActive(state, payload) {
            state.welcomePopupActive = payload;
        },
        setSupportPopupActive(state, payload) {
            state.supportPopupActive = payload;
        },
        setDevice(state, payload) {
            state.device = payload;
        },
        setNavigation(state, payload) {
            state.navigation = payload;
        },
        setCurrencies(state, payload) {
            state.currencies = payload;
        },
        setCountriesForRegister(state, payload) {
            state.countriesForRegister = payload;
        }
    },
    getters: {
        portalUrl: (state) => state.portalUrl,
        appUrl: (state) => state.appUrl,
        apiUrl: (state) => state.apiUrl,
        supportBotName: (state) => state.supportBotName,
        supportBotUrl: (state) => state.supportBotUrl,
        senderBotName: (state) => state.senderBotName,
        instructionPopupActive: (state) => state.instructionPopupActive,
        welcomePopupActive: (state) => state.welcomePopupActive,
        supportPopupActive: (state) => state.supportPopupActive,
        device: (state) => state.device,
        isMobile: (state) => state.device === 'mobile',
        navigation: (state) => state.navigation,
        posterAppId: (state) => state.posterAppId,
        posterRedirectUri: (state) => state.posterRedirectUri,
        currencies: (state) => state.currencies,
        countriesForRegister: (state) => state.countriesForRegister
    }
};
