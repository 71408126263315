export const checkValidity = (val, rules) => {
    let result = true;
    for (let i = 0; i < rules.length; i += 1) {
        if (rules[i](val) !== true) {
            result = false;
            break;
        }
    }
    return result;
};
export const getElementCoordinates = (target) => {
    const box = target.getBoundingClientRect(),
        body = document.body,
        docEl = document.documentElement,
        scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
        scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft,
        clientTop = docEl.clientTop || body.clientTop || 0,
        clientLeft = docEl.clientLeft || body.clientLeft || 0,
        top = box.top + scrollTop - clientTop,
        left = box.left + scrollLeft - clientLeft;

    return {
        top,
        left
    };
};
export const getEventCoordinates = (event) => {
    const body = document.body,
        docEl = document.documentElement,
        scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
        scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft,
        clientTop = docEl.clientTop || body.clientTop || 0,
        clientLeft = docEl.clientLeft || body.clientLeft || 0,
        top = event.clientY + scrollTop - clientTop,
        left = event.clientX + scrollLeft - clientLeft;

    return {
        top,
        left
    };
};
export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const scrollToElement = (element) => {
    element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
};
export const convertToFormData = (data) => {
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    return formData;
};
export const isMobile = () => {
    const widthBreakPoint = 1024;
    let rect = document.documentElement.clientWidth;
    return rect < widthBreakPoint;
};
export const generateGetUrl = (url, params) => {
    if (params && Object.keys(params).length) {
        url += '?';
        for (let key in params) {
            url += key + '=' + params[key] + '&';
        }
        if (url.endsWith('&')) {
            url = url.slice(0, -1);
        }
    }
    return url;
};
export const currencyTransformer = (currency, format = 'short') => {
    let result;
    if (format === 'short') {
        switch (currency) {
            case 'UAH':
                result = 'uah_short';
                break;
            case 'RUB':
                result = 'rub_short';
                break;
            case 'USD':
                result = 'usd_short';
                break;
            case 'EUR':
                result = 'eur_short';
                break;
            default:
                result = 'uah_short';
                break;
        }
    }
    if (format === 'long') {
        switch (currency) {
            case 'UAH':
                result = 'uah_long';
                break;
            case 'RUB':
                result = 'rub_long';
                break;
            case 'USD':
                result = 'usd_long';
                break;
            case 'EUR':
                result = 'eur_long';
                break;
            default:
                result = 'uah_long';
                break;
        }
    }
    return result;
};
export const generateHash = (length) => {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }

    return result;
};
export const swapArrayItems = (array, indexA, indexB) => {
    const arrayClone = [...array];
    const temp = arrayClone[indexB];
    arrayClone[indexB] = arrayClone[indexA];
    arrayClone[indexA] = temp;
    return arrayClone;
};
